.business-bg-wrapper{
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 0;
}
.business-bg-wrapper img{
  height: 100vh;
}

.business-house-c{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: center;
}
.business-bg img,
.business-house-c img{
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  min-width: 100%;
}

.business-tree-r,
.business-tree-l,
.business-house-l,
.business-house-r{
  position: absolute;
  top: 0;
  height: 100%;
}
.business-tree-l,
.business-house-l{
  left: 0;
}
.business-tree-r,
.business-house-r{
  right: 0;
}