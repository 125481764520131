.hands{
    max-width: 80%;
    position: relative;
    text-align: center;
    transform-origin: top center;

    top: -100vmax;
    animation: money-fall 1000ms ease-out 0s 1 normal forwards;
}
.home-page-wrapper{
    justify-content: flex-start;
}
.hand-parts{
    position: relative;
}
.hand-parts img{
    width: 100%;
}
.no-sign,
.money,
.hand-front{
    position: absolute;
}
.hand-front{
    top: 0;
}
.money{
    top: -100vmax;

    animation-name: money-fall, fadeout, fadeinsteps01;
    animation-duration: 1000ms, 400ms, 4000ms;
    animation-delay: 500ms, 4s, 6s;
    animation-timing-function: ease-out, ease-out, ease-in-out;
    animation-iteration-count: 1, 1, infinite;
    animation-direction: normal, normal, alternate;
    animation-fill-mode: forwards, forwards, forwards;
}
.no-sign{
    top: 0;
    opacity: 0;
    animation: fadeinsteps 400ms ease-out 3.5s 1 normal forwards,
    fadeinsteps10 4000ms ease-in-out 6s infinite alternate forwards;
}
.title-sign{
    margin: 0 30px;
    opacity: 0;
    animation: fadeinsteps 400ms ease-out 2.2s 1 normal forwards;
}

.start-game{
    position: relative;
    display: inline-block;
    color: #c55026;
    text-transform: uppercase;
    text-decoration: none;
    background: #f8c5a5;
    padding: 20px;
    border-radius: 30px;
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
    box-shadow: #9856217a 0 0 8px 0px;
    animation: fadein 400ms ease-out 5s 1 normal both;
    transition: all ease-in-out 100ms;
    transform: scale(1);
}
.start-game:hover{
    transform: scale(1.1);
}

@media (min-width: 767px) {
    .shift-top{
        top: -30px;
    }
    .no-sign{
        top: -30px
    }
    .no-sign img{
        width: 100%;
        transform: scale(1.25);
    }
    .hands{
        transform: scale(0.8);
        margin-top: -180px;
    }
    .start-game{
        margin-top: 15px;
    }
}
@media (min-width: 1370px) {
    .hands{
        transform: scale(0.9);
        margin-top: -180px;
    }
}

@keyframes slidetop {
    0% {
        margin-top: -150px;
    }
    100% {
        margin-top: -260px;
    }
}
@keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeinsteps {
    0% {
        opacity: 0;
    }
    25%{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeinsteps01 {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@keyframes fadeinsteps10 {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
@keyframes money-fall {
    0% {
        top: -100vmax;
    }
    100% {
        top: 0vmax;
    }
}