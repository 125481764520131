.university-bg-wrapper{
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 0;
}
.university-bg-wrapper img{
  height: 100vh;
}

.university-house-c{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: center;
}
.university-bg img,
.university-house-c img{
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  min-width: 100%;
}

.university-tree-r,
.university-tree-l,
.university-house-l,
.university-house-r{
  position: absolute;
  top: 0;
  height: 100%;
}
.university-tree-l,
.university-house-l{
  left: 0;
}
.university-tree-r,
.university-house-r{
  right: 0;
}