.form-row{
  margin-bottom: 20px;
  label{
    display: inline-block;
    margin-bottom: 10px;
    font-weight: 600;
  }
}

.form-control{
  display: block;
  width: 100%;
  //height: calc(16px + 10px + 2px);
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  &.error{
    border-color: #b33c3c;
  }
}

@media (min-width: 1000px) {
  .form-control{
    font-size: 16px;
  }
}