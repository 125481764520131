.panel-wrapper {
  background-color: rgba(255, 255, 255, 0.95);
  width: 100%;
  margin-bottom: 20px;
  padding: 15px 0;

  display: flex;
  align-items: center;

  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.24);

  animation-name: slideIn;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  overflow: hidden;
  opacity: 1;

  & .character{
    max-width: 140px;
    margin: 10px 0 10px 10px;
    &:hover{
      cursor: inherit;
    }
    .icon img{
      box-shadow: inset 0px 0px 0px 5px #bbb;
    }
  }
}
.progress-bars {
  padding: 0;
  margin: 0 20px;
  width: 100%;
}
.progress-bar-wrapper{
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .legend{
    margin-top: 10px;
    text-align: center;
    color: #c55027;
    font-size: 12px;
    &.carma{
      font-size: 12px;
    }
  }
}
.achievements-clmn-wrapper{
  padding: 10px 0;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  .achievement-clmn{
    flex: 1 0 0;
    display: flex;
    flex-direction: row;
  }
  img{
    width: 25px;
  }
  .achievement-clmn-positive{
    img{
      margin-right: 3px;
    }
  }
  .achievement-clmn-negative{
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 0;
    img{
      margin-left: 3px;
    }
  }
}
.progress-bar-vertical {
  width: 20px;
  min-height: 350px;
  display: none;
  align-items: flex-end;
  float: left;
  background-color: #ccc;
  position: relative;
  border-radius: 5px;
  .progress-bar {
    width: 100%;
    height: 0;
    -webkit-transition: height 0.6s ease;
    -o-transition: height 0.6s ease;
    transition: height 0.6s ease;
    border-radius: 5px;
    position: absolute;
    background-color: #50abbe;
    span{
      display: block;
      position: relative;
      width: 24px;
      left: -8px;
      top: -15px;
      height: 24px;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      border-radius: 32px;
      background: #c55027;
      padding: 6px;
      color: #fff;
    }
  }
}
.progress-bar-horizontal{
  display: flex;
  height: 20px;
  background-color: #ccc;
  border-radius: 5px;
  width: 100%;
  .progress-bar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #50abbe;
    border-radius: 5px;

    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
    span{
      position: relative;
      display: block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 32px;
      background: #c55027;
      padding: 6px;
      color: #fff;
      right: -17px;
    }
  }
}
@keyframes slideIn {
  from {right: -100%; opacity: 0}
  to {right: 2vw; opacity: 1}
}
@keyframes slideOut {
  from {right: 2vw; opacity: 1}
  to {right: -100%; opacity: 0}
}

@media (min-width: 1000px) {
  .panel-wrapper {
    display: block;
    padding: 0;
    max-width: 250px;
    align-items: normal;
    margin-bottom: 0;
    & .character{
      max-width: 180px;
      margin: 20px auto;
    }
  }
  .progress-bar-wrapper{
    width: calc(50% - 15px);
    padding: 5px;
    flex-grow: 1;
    flex-basis: 0;
    .legend{
      margin-top: 8px;
      font-size: 16px;
    }
  }
  .progress-bar-vertical {
    display: flex;
  }
  .progress-bar-horizontal {
    display: none;
  }
  .progress-bars {
    display: flex;
    //flex-direction: column;
    width: auto;
    margin: 0 0 20px 0;
    padding: 0 10px;
    flex-direction: row;
  }
  .achievements-clmn-wrapper{
    flex-direction: column;
    width: 30px;
    padding: 5px 0;
    img{
      width: 30px;
    }
    .achievement-clmn{
      flex-direction: column;
    }
    .achievement-clmn-positive{
      img{
        margin: 0 0 4px 0;
      }
    }
    .achievement-clmn-negative {
      padding-bottom: 30px;

      img {
        margin: 4px 0 0 0;
      }
    }
  }
}