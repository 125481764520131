.stage-wrapper{
  position: relative;
  display: flex;
  align-items: center;
  justify-items: center;

  padding: 15px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.24);
}
.stage-content-wrapper{
  text-align: center;
  width: 100%;
  h1{
    padding: 0;
    margin: 0 0 20px 0;
    font-size: 20px;
    line-height: 24px;
    color: #c55027;
  }
}
.stages-wrapper{
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  .stage{
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: all 500ms;
    color: #404040;
    box-shadow: inset 0px 0px 0px 5px #bbb;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 75px;
    &:hover{
      box-shadow: inset 0px 0px 0px 10px #c55027;
      color: #c55027;
    }
  }
}
.stage-widget-wrapper{
  padding: 10px 0 20px 0;
  .title{
    font-size: 16px;
    color: #c55027;
    margin: 20px 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }
  .stages-wrapper{
    margin: 0;
    justify-content: center;
    .stage{
      cursor: default;
      width: 80px;
      height: 80px;
      border-radius: 40px;
      font-size: 11px;
      box-shadow: inset 0px 0px 0px 2px #bbb;
      color: #404040;
      margin: 0;
      flex-direction: column;
      background-color: #fff;
      &:first-of-type{
        margin-right: 20px;
      }
      span{
        color: #404040;
        margin-top: 2px;
        font-size: 11px;
        line-height: 11px;
      }
      &.active{
        box-shadow: inset 0px 0px 0px 3px #c55027;
        color: #c55027;
        &:hover{
          box-shadow: inset 0px 0px 0px 3px #c55027;
          color: #c55027;
        }
      }
      &:hover{
        color: #404040;
        box-shadow: inset 0px 0px 0px 3px #bbb;
      }
    }
  }
}

@media (min-width: 1000px) {
  .stage-widget-wrapper{
    padding: 20px 0 0 0;
    .stages-wrapper{
      justify-content: flex-end;
      .stage{
        width: 100px;
        height: 100px;
        border-radius: 50px;
        font-size: 13px;
        box-shadow: inset 0px 0px 0px 3px #bbb;
        span{
          margin-top: 5px;
          font-size: 13px;
          line-height: 13px;
        }
        &.active{
          box-shadow: inset 0px 0px 0px 5px #c55027;
          &:hover{
            box-shadow: inset 0px 0px 0px 5px #c55027;
          }
        }
      }
    }
  }
  .stage-wrapper{
    padding: 25px;
    margin-right: 50px;
    width: 100%;
  }
  .stage-content-wrapper{
    h1{
      font-size: 26px;
      line-height: 32px;
      margin: 0 0 30px 0;
    }
  }
}