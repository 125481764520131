.comment-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  //background-color: rgba(255, 255, 255, 0.98);
  //background: linear-gradient(180deg, #f2f8f1 0%, #fac79e 100%);
  top: 0;
  left: 0;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .comment-text{
    font-size: 18px;
    margin-bottom: 30px;
    padding: 0 30px;
    text-align: center;
  }
  .comment-btn{
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    background: #c55026;
    padding: 10px 15px;
    border-radius: 19px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all ease-in-out 100ms;
    transform: scale(1);
    &:hover{
      transform: scale(1.1);
    }
  }
  .comment-params {
    margin-bottom: 30px;
    color: #c55027;
    font-size: 16px;
    span{
      display: inline-block;
      margin: 0 auto 5px;
      position: relative;
      width: 20px;
      height: 20px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      border-radius: 20px;
      background: #c55027;
      padding: 6px;
      color: #fff;
    }
  }
}

@media (min-width: 1000px) {
  .comment-wrapper {
    .comment-text{
      font-size: 20px;
    }
  }
}


@keyframes fIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}