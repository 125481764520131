.final-content{
  text-align: center;
  .final-title{
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
    color: #c55027;
    font-weight: bold;
  }
  .final-message{
    font-size: 14px;
    line-height: 20px;
    padding: 0 10px;
    margin-bottom: 20px;
  }
  .final-params {
    margin-bottom: 20px;
    color: #c55027;
    font-size: 16px;
    span{
      display: block;
      margin: 0 auto 5px;
      position: relative;
      width: 36px;
      height: 36px;
      font-size: 18px;
      line-height: 36px;
      text-align: center;
      border-radius: 36px;
      background: #c55027;
      padding: 6px;
      color: #fff;
    }
  }
  .final-share{
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    a{
      display: inline-block;
      text-decoration: none;
      color: #fff;
      margin: 0 3px;
      opacity: 1;
      cursor: pointer;
      transition: opacity 100ms ease;
      &:hover{
        opacity: .8;
      }
    }
    img{
      max-width: 30px;
    }
  }
  .final-more{
    margin-bottom: 30px;
    border-top: 1px solid #dcdcdc;
    padding-top: 30px;
    font-size: 14px;
    &>div{
      display: flex;
      justify-content: center;
      align-items: center;
      &:first-of-type{
        margin-bottom: 7px;
      }
    }
    a{
      display: inline-block;
      text-decoration: none;
      color: #fff;
      margin: 0 3px;
      opacity: 1;
      cursor: pointer;
      transition: opacity 100ms ease;
      &:hover{
        opacity: .8;
      }
    }
    img{
      max-width: 50px;
    }
  }
}

@media (min-width: 1000px) {
  .final-content{
    .final-title {
      font-size: 26px;
      line-height: 34px;
      margin-bottom: 20px;
    }
    .final-message{
      font-size: 16px;
      line-height: 24px;
      padding: 0 30px;
    }
    .final-share,
    .final-more{
      font-size: 16px;
    }
  }
}