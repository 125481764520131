.game-wrapper{
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 15px;
  display: flex;
  flex-direction: column-reverse;
  //min-height: 100vh;
}
.break {
  flex-basis: 100%;
  height: 0;
}

@media (min-width: 1000px) {
  .game-wrapper{
    align-content: center;
    justify-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
}