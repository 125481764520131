@import-normalize;
html{
  font-size: 62.5%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  background: linear-gradient(180deg, #f2f8f1 0%, #fac79e 100%);
  height: 100vh;
}

button{
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
  transition: transform ease-in-out 100ms;
  transform: scale(1);
  position: relative;
}
button:hover{
  transform: scale(1.1);
}

.page-wrapper{
  //height: 100vh;
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

div.transition-group {
  position: relative;
}
section.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.fade-enter {
  opacity: 0.01;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
.tooltip-pos,
.tooltip-theme {
  color: #fff !important;
  background-color: #c55027 !important;
  &.place-top {
    &:after {
      border-top-color: #c55027 !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom-color: #c55027 !important;
      border-bottom-style: solid !important;
      border-bottom-width: 6px !important;
    }
  }
  &.place-right {
    &:after {
      border-right-color: #c55027 !important;
      border-right-style: solid !important;
      border-right-width: 6px !important;
    }
  }
}
.tooltip-pos{
  background-color: #046d04 !important;
  &.place-top {
    &:after {
      border-top-color: #046d04 !important;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom-color: #046d04 !important;
    }
  }
}
.tooltip-neg{
  background-color: #b33c3c !important;
  &.place-top {
    &:after {
      border-top-color: #b33c3c !important;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom-color: #b33c3c !important;
    }
  }
}

@media (min-width: 1000px) {
  .page-wrapper{
    height: 100vh;
    padding: 0;
    justify-content: center;
  }
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}