.achievement-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  top: 0;
  left: 0;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .achievement-icon{
    margin-bottom: 20px;
    img{
      max-width: 40px;
    }
  }
  .achievement-subtitle{
    font-size: 16px;
    margin-bottom: 20px;
    color: #4d545a;
  }
  .achievement-title {
    font-size: 26px;
    margin-bottom: 20px;
    color: #c55026;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
  }
  .achievement-text{
    font-size: 16px;
    margin-bottom: 20px;
    padding: 0 20px;
    text-align: center;
  }
  .achievement-btn{
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    background: #c55026;
    padding: 10px 15px;
    border-radius: 19px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all ease-in-out 100ms;
    transform: scale(1);
    &:hover{
      transform: scale(1.1);
    }
  }
  &.good{
    background: linear-gradient(180deg, #ecfaf3 0%, #aeffb3 100%);
    .achievement-title{
      color: #046d04;
    }
    .achievement-btn{
      background: #046d04;
    }
  }
  &.bad{
    background: linear-gradient(180deg, #fae3e8 0%, #fab894 100%);
    .achievement-title{
      color: #b33c3c;
    }
    .achievement-btn{
      background: #b33c3c;
    }
  }
}

@media (min-width: 1000px) {
  .achievement-wrapper {
    .achievement-subtitle{
      font-size: 18px;
      margin-bottom: 30px;
    }
    .achievement-title {
      font-size: 30px;
      margin-bottom: 30px;
    }
    .achievement-text{
      font-size: 20px;
      margin-bottom: 30px;
      padding: 0 30px;
    }
    .achievement-icon{
      margin-bottom: 30px;
      img{
        max-width: 60px;
      }
    }
  }
}