.question-wrapper{
  position: relative;
  width: 100%;
  margin-right: 0;

  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.24);
}
.questioner-wrapper{
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;

  padding: 0px;
  margin-right: 0;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  &.hide-mobile{
    display:none;
  }
  .img{
    max-width: initial;
    width: 80px;
    img{
      width: 100%;
    }
  }
  .title{
    text-align: center;
    margin-top: 30px;
    font-size: 18px;
    text-transform: uppercase;
    color: #c55027;
    font-weight: bold;
  }
  &.show-mobile{
    display: block;
    float: left;
    margin-right: 10px;
    img{
      max-width: 80px;
      margin: 0 auto;
      display: block;
    }
    div{
      font-size: 11px;
      margin-top: 10px;
      text-align: center;
      font-weight: 400;
    }
  }
}
.question-content-wrapper{
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;

  padding: 10px;
  position: relative;
  min-height: 300px;
  h1{
    padding: 0;
    margin: 0;
    font-size: 19px;
    line-height: 23px;
    color: #c55027;
  }
}
.answers-wrapper{
  margin-top: 15px;
  display: flex;
  .answer{
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 15px;
    transition: all 500ms;
    &:before{
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: #c55027;
      border-radius: 4px;
      margin-right: 7px;
      position: relative;
      top: -1px;
    }
    &:hover{
      opacity: .6;
    }
  }
}
.stage-intro-text{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 19px;
  line-height: 23px;
  color: #c55027;
  font-weight: 600;
  text-align: center;
}

.intro-wrapper{
  background: none;
  box-shadow: none;
  transform: translateY(50%);
  .answers-wrapper{
    margin-top: 0;
    display: block;
  }
  .question-content-wrapper{
    min-height: initial;
  }
  .questioner-wrapper{
    .img{
      animation-name: intro-in;
      animation-duration: 300ms;
      animation-delay: 1000ms;
      animation-fill-mode: both;
      width: 150px;
      img{
        max-width: 150px;
        width: 100%;
      }
    }
  }
  .stage-intro-text{
    position: relative;
    animation-name: intro-in;
    animation-duration: 500ms;
    animation-delay: 1800ms;
    animation-fill-mode: both;

    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.24);
    padding: 28px 10px;
    width: auto;
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-top: 25px solid transparent;
      border-right: 18px solid rgba(255, 255, 255, 0.95);
      left: -18px;
      top: 30%;
      transform: translateY(-30%);
    }
  }
}

@media (min-width: 1000px) {
  .question-wrapper{
    display: flex;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    margin-right: 30px;
    .col{
      display: flex;
      flex-direction: column;
      flex: 1 0;
    }
  }
  .questioner-wrapper{
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.24);
    padding: 20px;
    margin-right: 30px;
    &.show-mobile{
      display: none;
    }
    &.hide-mobile{
      display: flex;
    }
    .img {
      max-width: 230px;
      width: initial;
    }
  }
  .question-content-wrapper{
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.24);
    padding: 30px;
    flex: 1 0;
    min-height: initial;
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;

      border-top: 35px solid transparent;
      //border-bottom: 20px solid transparent;
      border-right: 22px solid rgba(255, 255, 255, 0.95);
      left: -22px;
      top: 30%;
      transform: translateY(-30%);
    }
    h1{
      font-size: 26px;
      line-height: 32px;
    }
  }
  .answers-wrapper{
    display: block;
    margin-top: 30px;
    .answer{
      font-size: 18px;
      margin-bottom: 20px;
      &:before{
        top: -3px;
        margin-right: 10px;
      }
    }
  }
  .stage-intro-text{
    font-size: 26px;
    line-height: 32px;
  }
  .intro-wrapper{
    margin-left: 100px;
    transform: none;
    .answers-wrapper{
      display: flex;
    }
    .question-content-wrapper{
      flex: initial;
      max-width: 400px;
      top: 100px;

      animation-name: intro-in;
      animation-duration: 500ms;
      animation-delay: 2000ms;
      animation-fill-mode: both;
    }
    .stage-intro-text{
      animation: none;

      background: none;
      border-radius: 0;
      box-shadow: none;
      padding: 0;
    }
    .questioner-wrapper{
      background: none;
      box-shadow: none;
      .img{
        width: auto;
        max-width: 290px;
        img{
          max-width: 100%;
        }
      }
    }
  }
}

@keyframes intro-in {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}