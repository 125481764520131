.toolbar{
  position: relative;
  top: 0;
  margin: 10px 0;
  z-index: 10;
  a{
    color: #000;
    z-index: 111;
    width: 30px;
    display: inline-block;
    margin: 0 10px;
    img{
      width: 100%;
      transition: all 200ms ease-in-out;
      &:hover{
        transform: scale(1.1);
      }
    }
  }
}

@media (min-width: 1000px) {
  .toolbar{
    position: absolute;
    margin: 0;
    top: 10px;
  }
}