.modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 11; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    animation-name: fadeIn;
    animation-duration: 500ms
}
.modal-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    width: 94%;
    max-width: 900px;

    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.24);

    //animation-name: slideIn;
    //animation-duration: 500ms;
}

.close {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    float: right;
    font-size: 28px;
    font-weight: bold;
    opacity: 1;
}
.close:hover,
.close:focus {
    opacity: .8;
}

.modal-header {
    padding: 15px;
    text-align: center;
}
.modal-header h2{
    margin: 0;
    padding: 0;
    color: #333333;
    font-size: 2.6rem;
}
.modal-body {
    font-size: 14px;
    line-height: 18px;
    padding: 0 15px;
    p{
        margin: 0 0 15px 0;
    }
}
.modal-footer {
    padding: 0 15px 20px 15px;
    text-align: center;
}

@keyframes slideIn {
    from {top: 0; opacity: 0}
    to {top: 50%; opacity: 1}
}
@keyframes fadeIn {
    from {opacity: 0}
    to {opacity: 1}
}
@keyframes fadeOut {
    from {opacity: 1}
    to {opacity: 0}
}

@media (min-width: 767px) {
    .modal-content{
        width: 70%;
    }
    .modal-body {
        padding: 0 30px;
        font-size: 16px;
        line-height: 20px;
    }
    .modal-footer {
        padding: 0 30px 40px 30px;
    }
    .modal-header {
        padding: 30px;
    }
    .modal-header h2{
        font-size: 4rem;
    }
}