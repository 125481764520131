.page-content{
  background: #fff;
  top: 30px;
  transform: translate(-50%, 0);
  br{
    display: none;
  }
  ul{
    padding-left: 20px;
    li{
      padding: 5px 0;
    }
  }
  a{
    color: #c55027;
  }

  .images-row {
    display: flex;
    &>div{
      padding: 0 10px;
      &:first-of-type{
        padding-left: 0;
      }
      &:last-of-type{
        padding-right: 0;
      }
    }
    img{
      display: block;
      width: 100%;
    }
  }
}