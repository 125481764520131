.character-selection-wrapper{
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    margin: 0 0 10px 0;
}
.character{
    margin: 0;
    overflow: hidden;
    position: relative;
    max-width: 110px;
}
.character .icon img{
    position: relative;
    width: 100%;
    box-shadow: inset 0px 0px 0px 5px #bbb;
    border-radius: 50%;
    transition: box-shadow 100ms;
}
.character:hover{
    cursor: pointer;
}
.character:hover .icon img,
.character.selected .icon img{
    box-shadow: inset 0px 0px 0px 5px #c55027;
}

.character-title{
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    color: #333333;
    margin: 10px 0 5px 0;
}

.character-desc{
    margin: 0 0 30px 0;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
}
.start-game-btn{
    display: inline-block;
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #c55026;
    padding: 20px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: all ease-in-out 100ms;
    transform: scale(1);
}
.start-game-btn:hover{
    transform: scale(1.1);
}

@media (min-width: 767px) {
    .character-desc{
        padding: 0 30px;
    }
    .character-title{
        font-size: 2.5rem;
    }
    .character-desc{
        font-size: 1.8rem;
        line-height: 2.4rem;
    }
    .start-game-btn{
        font-size: 24px;
    }
    .character-selection-wrapper{
        margin: 0 0 30px 0;
        align-items: center;
    }
    .character{
        margin: 0 10px;
        max-width: 285px;
    }
    .character-title{
        font-size: 2rem;
    }
    .character:hover .icon img,
    .character.selected .icon img{
        box-shadow: inset 0px 0px 0px 10px #c55027;
    }
}