.gov-bg-wrapper{
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 0;
}
.gov-bg-wrapper img{
  height: 100vh;
}
.gov-house-c{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: center;
}
.gov-bg img,
.gov-house-c img{
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  min-width: 100%;
}
.gov-house-r{
  position: absolute;
  top: 0;
  height: 100%;
}
.gov-house-r{
  right: 0;
}